import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import DynamicProductCarousel from '@src/components/DynamicProductCarousel'
import { ProductCardType } from '@utils/searchUtils'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Theme, Typography, useMediaQuery } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { CarouselProduct } from '@src/components/DynamicProductCarousel/DynamicProductCardTypes'
import storeValues from '@src/utils/storeValues'
import { useMultipleProductDetailPartialProductsQuery } from '@src/queries/MultipleProductDetailsQueryFullProduct.generated'
import { useAemDiscountedProductsQuery } from '@src/queries/AEMDiscountedProductsQuery.generated'
import DOMPurify from 'isomorphic-dompurify'
import { FormattedMessage } from 'react-intl'
import { useRecentlyViewedProductsQuery } from '@src/queries/RecentlyViewedProductsQuery.generated'
import { AnalyticsDispType, ProductInput } from '@src/types/graphql-types'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { ErpType } from '@src/utils/useProductErpType'
import clsx from 'clsx'
import { useBestSellerProductsQuery } from '@src/queries/BestSellerProductsQuery.generated'
import { recommendationProductsConst } from '@src/aem-content/aemUtils'
import SkeletonLoader from '../SkeletonLoader'
import { Linq } from '@src/components/Linq'
import { useRouter } from 'next/router'
import { parseRegionalUrl } from '@utils/regional'
import { useSessionStorage } from 'react-storage'
import {
  GA4EcommercePayload,
  GA4EcommercePayloadItem,
} from '@sial/common-utils'
import {
  sendAEMProductRecommendationsEvent,
  sendRecommendedProductsData,
} from '@src/utils/analytics'
import { useIntersectionObserver } from '@src/utils/useIntersectionObserver'
import { EventKeys } from '@src/utils/analytics/enums'
import { useUserSession } from '@src/utils/useUserSession'
import { useGetCountryDetailsQuery } from '@src/queries/CountryAccountSetupQuery.generated'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  error: {
    padding: theme.spacing(6, 5, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  heroProductVWrapper: {
    background: theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(5),
    border: 'solid 1px rgba(201, 201, 201, 0.5)',
    padding: `${theme.spacing(8, 6)} !important`,
    [theme.breakpoints.up('sm')]: {
      '.aem-GridColumn.aem-GridColumn--default--12 > &': {
        maxWidth: '50%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '390px',
    },
    '& $title': {
      textTransform: 'unset',
      letterSpacing: 'normal',
      margin: theme.spacing(0, 0, 10, 0),
    },
  },
  title: {
    marginBottom: theme.spacing(8),
    '$contentCondensed &': {
      marginBottom: theme.spacing(4),
    },
  },
  viewAll: {
    fontSize: theme.typography.pxToRem(16),
    display: 'inline-block',
    marginTop: theme.spacing(15),
  },
  wrapperPaddingBottom: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: `${theme.typography.pxToRem(103)} !important`,
    },
  },
  discount: {
    width: 'fit-content',
    padding: theme.spacing(0, 2.5),
    borderRadius: '2px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  contentCondensed: {
    padding: 0,
  },
}))

type AbTestingSessionStorageDataType = {
  productIds: ProductInput[]
  slug: string
}

const RelatedProducts: React.FC<any> = ({
  products,
  title,
  hideProductImg = false,
  cardsPerRotation,
  viewAllCTALink,
  viewAllText,
  cardType,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const heroCardRef = useRef<HTMLDivElement>(null)
  const { isBlueB2BUser, userIsLoggedIn } = useCurrentUser()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { userSession } = useUserSession()
  const [fetchNewArrivals, setFetchNewArrivals] = useState<boolean>(false)
  const [isRelatedProductsEmpty, setIsRelatedProductsEmpty] =
    useState<boolean>(false)
  const [carouselVisible, setCarouselVisible] = useState<boolean>(false)
  const [shouldShowLoader, setShouldShowLoader] = useState<boolean>(false)

  const updatedProductInput = products.map((item: any) => {
    delete item.defaultSrc
    return {
      ...item,
      catalogType: storeValues.sial,
    }
  })
  /**
   * Sets isProductHeroCard to true when the author selects "Hero Product Card" option
   * while configuring the related Products component.
   */
  const isProductHeroCard = cardType === 'hero'

  /**
   * Sets isStandardProductHeroCard to true when the author selects "Standard Hero Product Card" option
   * while configuring the related Products component.
   */
  const isStandardProductHeroCard = cardType === 'standard'

  /**
   * sets isPickupHeroProductCard to true when the author selects "Standard Hero Product Card" option
   * while configuring the related Products component for loggedin user.
   * and when no products are added from author
   */
  const isPickupHeroProductCard =
    isStandardProductHeroCard && !products.length && userIsLoggedIn

  /**
   * sets isPromotionalHeroCard to true when the author selects "Standard Hero Product Card" option
   * while configuring the related Products component for non-loggedin user.
   * and when no products are added from author
   */
  const isPromotionalHeroCard =
    isStandardProductHeroCard && !products.length && !userIsLoggedIn

  //sets isdefaultRelatedProducts to true when the author selects "Default" option while configuring the related Products component.
  const isdefaultRelatedProducts = cardType === 'default'

  /**
   * sets isHeroCard to true when the author selects "Hero Product Card"
   * or "Standard Hero Product Card" option while configuring the related Products component.
   */
  const isHeroCard =
    isProductHeroCard || isPickupHeroProductCard || isPromotionalHeroCard

  const isViewAllPresent =
    isDesktop && !!viewAllCTALink && !!viewAllText && isHeroCard

  /**
   * A/B Test - Custom related products. AEM3-1337
   * If there is an a/b test running, we first check the page slug to ensure the A/B test is for the page being viewed
   * Then we use the products IDs from session storage when displaying related products via useMultipleProductDetailPartialProductsQuery
   */

  const sessionStorageData = useSessionStorage<AbTestingSessionStorageDataType>(
    'optly-abTestingRelatedProducts'
  )

  const abTestingRelatedProducts =
    parseRegionalUrl(router.asPath).pathname === sessionStorageData?.slug &&
    sessionStorageData?.productIds

  /**
   * Fetching country details to get `salesOrgCode`
   */

  const { data: countryData, loading: countryDataLoading } =
    useGetCountryDetailsQuery({
      variables: {
        country: userSession?.country,
      },
      fetchPolicy: 'network-only',
      skip:
        !userSession?.country ||
        isdefaultRelatedProducts ||
        (isPickupHeroProductCard && !isRelatedProductsEmpty) ||
        isProductHeroCard,
    })

  const salesOrgCode = countryData?.getCountryDetails?.salesOrg ?? '7950'

  /**
   * useMultipleProductDetailPartialProductsQuery is called when the author selects "Default" or "Hero Product Card" option
   * while configuring the related Products component.
   * This is skipped if the author selects "Standard Hero Product Card" and products array is empty.That is because we fetch the details from the
   * cloud analytics API for that use case.
   */

  const { loading, error, data } = useMultipleProductDetailPartialProductsQuery(
    {
      variables: {
        productInputs: abTestingRelatedProducts
          ? abTestingRelatedProducts
          : updatedProductInput,
      },
      errorPolicy: 'ignore',
      ssr: false,
      skip:
        isPickupHeroProductCard || isPromotionalHeroCard || !products.length,
      // client
    }
  )

  /**
   * useRecentlyViewedProductsQuery is called when the author selects "Standard Hero Product Card" option while configuring the related
   *  Products component.
   * This is skipped if the author selects "Default" and for non logged-in users(isPromotionalHeroCard)
   */

  const {
    data: recentlyViewedProductsData,
    loading: recentlyViewedProductsLoading,
  } = useRecentlyViewedProductsQuery({
    variables: {
      dispType: AnalyticsDispType.RecentlyViewedRchp,
      productKey: '',
      brand: '',
      ...(isBlueB2BUser ? { erpType: ErpType.Blue } : {}),
    },
    ssr: false,
    fetchPolicy: 'no-cache',
    skip:
      isPromotionalHeroCard || isdefaultRelatedProducts || isProductHeroCard,
  })

  /**
   * useAemDiscountedProductsQuery is called when the author selects "Standard Hero Product Card" option while configuring the related
   *  Products component.
   * This is skipped if the author selects "Default" and for logged-in users(isPickupHeroProductCard)
   * useAemDiscountedProductsQuery is also used as a fall back for logged in users if useRecentlyViewedProductsQuery returns an empty response.
   */
  const { data: discountedProductsData, loading: discountedProductsLoading } =
    useAemDiscountedProductsQuery({
      variables: {
        input: {
          typeId: 'AEMDiscountedProductsAPI',
          disp: salesOrgCode,
        },
      },
      ssr: false,
      fetchPolicy: 'no-cache',
      skip:
        isdefaultRelatedProducts ||
        (isPickupHeroProductCard && !isRelatedProductsEmpty) ||
        isProductHeroCard ||
        !salesOrgCode ||
        countryDataLoading,
    })

  /**
   * useBestSellerProductsQuery is used as a fallback for non-loggedin users when useAemDiscountedProductsQuery returns an empty response.
   */
  const { loading: newArrivalsLoading, data: newArrivalsData } =
    useBestSellerProductsQuery({
      ssr: false,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          ...(isBlueB2BUser ? { erpType: ErpType.Blue } : {}),
          typeId: recommendationProductsConst['newProducts']?.typeId,
          disp: recommendationProductsConst['newProducts']?.disp,
        },
      },
      skip: !fetchNewArrivals,
    })

  const isDiscountedProductsPresent =
    discountedProductsData?.getAEMDiscountedProducts?.products.length

  const isRelatedProductsPresent =
    recentlyViewedProductsData?.getRecentlyViewedProducts?.products.length

  const setCardStyling = (
    cards: NodeListOf<HTMLElement>,
    height: string,
    position: string
  ) => {
    Array.from(cards).forEach((card) => {
      const ctaLink: HTMLAnchorElement | null = card.querySelector(
        '.MuiTypography-root.MuiTypography-body1 a.ctaLinkElement'
      )
      card.style.height = height
      if (ctaLink) ctaLink.style.position = position
    })
  }

  const setCarouselTitle = () =>
    title ? (
      !isRelatedProductsEmpty ? (
        <Typography
          variant={isHeroCard ? 'h3' : 'h2'}
          className={classes.title}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(title ?? ''),
          }}
        />
      ) : (
        <Typography variant="h3" className={classes.title}>
          <FormattedMessage id="TOP_DEALS" defaultMessage="Top Deals" />
        </Typography>
      )
    ) : (
      <></>
    )

  const setHeroCardHeight = useCallback(() => {
    //Added a timeout just to make sure we have the correct height before setting it.
    setTimeout(() => {
      const cards: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.heroGrid12 .cardTopWrapper > div'
      )
      if (cards.length) {
        //Reset the card height to initial values
        setCardStyling(cards, 'auto', 'unset')
        // Calculate the maximum height among all cards in a row
        const heights = Array.from(cards).map((card) => card.offsetHeight)
        const maxHeight = Math.max(...heights)
        // Set the height of all cards in the row to the maximum height
        setCardStyling(cards, `${maxHeight}px`, 'absolute')
      }
      const currentCardRef = heroCardRef && heroCardRef.current
      if (!currentCardRef) return
      currentCardRef.style.height = 'auto'
      const siblingGridColumn =
        currentCardRef?.parentElement?.nextElementSibling
      if (!siblingGridColumn) return
      const cardSibling = siblingGridColumn?.querySelector('.cardTopWrapper')
      if (!cardSibling) return
      const cardSiblingHeight = cardSibling?.clientHeight
      const currentCardRefHeight = currentCardRef?.offsetHeight
      if (cardSiblingHeight) {
        if (currentCardRefHeight && cardSiblingHeight < currentCardRefHeight) {
          currentCardRef.style.height = `${currentCardRefHeight}px`
          setCardStyling(cards, `${currentCardRefHeight}px`, 'absolute')
        } else currentCardRef.style.height = `${cardSiblingHeight}px`
      } else currentCardRef.style.height = 'auto'
    }, 100)
  }, [heroCardRef])

  const getRelatedProducts = (): CarouselProduct[] => {
    let relatedProductData: CarouselProduct[] | undefined = []
    if (data && data.getMultipleProductDetails.length) {
      relatedProductData = data.getMultipleProductDetails.filter(
        Boolean
      ) as CarouselProduct[]
    } else if (
      recentlyViewedProductsData &&
      recentlyViewedProductsData.getRecentlyViewedProducts?.products.length
    ) {
      relatedProductData = recentlyViewedProductsData.getRecentlyViewedProducts
        ?.products as CarouselProduct[]
    } else if (discountedProductsData && isDiscountedProductsPresent) {
      relatedProductData = discountedProductsData.getAEMDiscountedProducts
        ?.products as CarouselProduct[]
    } else if (
      newArrivalsData &&
      newArrivalsData.getBestSellerProducts?.products.length
    ) {
      relatedProductData = newArrivalsData.getBestSellerProducts
        ?.products as CarouselProduct[]
    }

    if (relatedProductData.length > 0) {
      return relatedProductData.map((product) => ({
        ...product,
        gaProductCode:
          product?.gaProductCode ||
          `aem_related_products|aemrelatedproducts|${product?.brand?.key?.toLowerCase()}_${product?.productNumber?.toLowerCase()}`,
      }))
    }

    return relatedProductData
  }

  const displayDiscount = (discountPercent?: string | null) =>
    discountPercent ? (
      <div className={classes.discount}>
        <FormattedMessage
          id="DISCOUNT_PERCENTAGE"
          defaultMessage="{discountPercent}% off"
          values={{
            discountPercent,
          }}
        />
      </div>
    ) : null

  useEffect(() => {
    setShouldShowLoader(
      loading ||
        recentlyViewedProductsLoading ||
        discountedProductsLoading ||
        newArrivalsLoading ||
        countryDataLoading
    )
  }, [
    loading,
    recentlyViewedProductsLoading,
    discountedProductsLoading,
    newArrivalsLoading,
    countryDataLoading,
  ])

  useEffect(() => {
    if (discountedProductsData && !isDiscountedProductsPresent)
      setFetchNewArrivals(true)
  }, [discountedProductsData])

  useEffect(() => {
    if (recentlyViewedProductsData && !isRelatedProductsPresent)
      setIsRelatedProductsEmpty(true)
  }, [recentlyViewedProductsData])

  useEffect(() => {
    const heroCardContainer = document.querySelector('.heroWrapperDiv')
    const heroCardContainerParent = heroCardContainer?.parentElement
    const heroCardContainerGrid = heroCardContainerParent?.parentElement
    heroCardContainerParent?.classList.add('heroGridColumn')
    heroCardContainerGrid?.classList.add('heroGrid12')
    isMobile && setHeroCardHeight()
  }, [
    data,
    recentlyViewedProductsData,
    discountedProductsData,
    shouldShowLoader,
  ])

  useEffect(() => {
    window.addEventListener('resize', setHeroCardHeight)
    return () => {
      window.removeEventListener('resize', setHeroCardHeight)
    }
  }, [])
  const relatedProductData = getRelatedProducts()

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.75,
  }

  const relatedProductsGA4Payload = (
    relatedProductData: CarouselProduct[],
    isMobile: boolean
  ): GA4EcommercePayload => {
    const ga4Payload: GA4EcommercePayloadItem[] = []
    const visibleProducts = relatedProductData.slice(
      0,
      isMobile ? 1 : isdefaultRelatedProducts ? 4 : 2
    )

    for (const [index, product] of visibleProducts.entries()) {
      const [promotionId, promotionName, creativeName] =
        product.gaProductCode?.split('|') || []
      const ga4PayloadItem: GA4EcommercePayloadItem = {
        creative_name: creativeName?.toLowerCase(),
        promotion_id: promotionId?.toLowerCase(),
        promotion_name: promotionName?.toLowerCase(),
        index: index + 1,
        item_id: product?.productNumber?.toLowerCase(),
        item_brand: product?.brand?.key?.toLowerCase(),
        item_list_id: 'homepage',
        item_list_name: 'homepage',
      }
      ga4Payload.push(ga4PayloadItem)
    }

    return {
      creative_slot: 'related_products - homepage',
      items: ga4Payload,
    }
  }

  const relatedProductsItemView: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting || carouselVisible) return

      const payload = relatedProductsGA4Payload(relatedProductData, isMobile)
      sendAEMProductRecommendationsEvent(payload)
      setCarouselVisible(true)
    })
  }

  const relateProductsdDivRef = useIntersectionObserver<HTMLDivElement>(
    relatedProductsItemView,
    options
  )

  useEffect(() => {
    if (!relatedProductData || !relatedProductData.length) return

    sendRecommendedProductsData(
      isProductHeroCard
        ? EventKeys.PRODUCT_HERO_CARD
        : EventKeys.RELATED_PRODUCTS,
      relatedProductData
    )

    const handleSessionStorage = () => {
      sessionStorage.removeItem(EventKeys.RELATED_PRODUCTS)
      sessionStorage.removeItem(EventKeys.PRODUCT_HERO_CARD)
    }

    router.events.on('routeChangeStart', handleSessionStorage)

    return () => {
      router.events.off('routeChangeStart', handleSessionStorage)
    }
  }, [relatedProductData])

  if (shouldShowLoader) return <SkeletonLoader count={isHeroCard ? 2 : 4} />
  if (error) {
    console.error(error)
    if (products && products.length > 0) {
      return (
        <div className={classes.error}>
          <ErrorIcon />
          <span>Something went wrong, please try again.</span>
        </div>
      )
    } else {
      return null
    }
  } else {
    const relatedProductData = getRelatedProducts()

    return relatedProductData &&
      relatedProductData?.length > 0 &&
      Array.isArray(relatedProductData) ? (
      <div
        id="aem-related-products"
        className={clsx({
          [classes.heroProductVWrapper]: isHeroCard,
          [classes.wrapperPaddingBottom]: !isViewAllPresent && isHeroCard,
          heroWrapperDiv: isHeroCard,
        })}
        ref={isHeroCard ? heroCardRef : null}
      >
        <div ref={relateProductsdDivRef}>
          <DynamicProductCarousel
            titleOverride={setCarouselTitle()}
            type={
              isProductHeroCard
                ? ProductCardType.ProductHeroCard
                : ProductCardType.Related
            }
            products={relatedProductData}
            productHeroCard={isHeroCard}
            hideProductImg={hideProductImg}
            withoutControls={
              isDesktop && relatedProductData.length <= cardsPerRotation
            }
            displayDiscount={displayDiscount}
            parentPageName="homepage"
            parentPageNameDetail="homepage"
          />

          {isViewAllPresent && (
            <Linq href={viewAllCTALink} className={classes.viewAll}>
              {viewAllText}
            </Linq>
          )}
        </div>
      </div>
    ) : null
  }
}

export default MapTo('cms-commons/components/content/relatedproducts')(
  RelatedProducts
)
